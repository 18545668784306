import { useI18next } from 'gatsby-plugin-react-i18next'
import { useCallback, useMemo } from 'react'

const useCustomI18n = () => {
  const isBrowser = typeof window !== 'undefined'
  const localStorage = isBrowser ? window.localStorage : null

  // languageがどのような場合でも「en」を指すため、別のアプローチを利用
  // const { navigate, language } = useI18next()
  const { navigate } = useI18next()

  const currentlanguage = useMemo(() => {
    const path = window.location.pathname
    if (path.startsWith('/en')) {
      return 'en'
    }

    if (!path.startsWith('/en')) {
      return 'ja'
    }
  }, [])

  const userRequiredLanguage = useMemo(() => {
    const languageMode = localStorage?.getItem('languageMode')
    if (languageMode !== null) {
      return languageMode
    }
    return navigator.language === 'ja' || navigator.language === 'ja-JP'
      ? 'ja'
      : 'en'
  }, [localStorage])

  const changeCurrentLanguage = useCallback(
    (langauge: 'en' | 'ja') => {
      const path = window.location.pathname
      if (langauge === 'en') {
        if (!path.startsWith('/en')) {
          void navigate(`/en${path}`)
        }
      } else {
        if (path.startsWith('/en')) {
          void navigate(path.replace('/en', ''))
        }
      }
    },
    [navigate]
  )

  return { currentlanguage, userRequiredLanguage, changeCurrentLanguage }
}

export default useCustomI18n
